import {
  Box,
  Button,
  ContentBlock,
  ResponsivePlayer,
  Section,
  Stack,
  Text,
  TextContent,
} from "@dangerfarms/ui-system";
import { PrismicText } from "@dangerfarms/prismic";
import {
  Page,
  Testimonial,
  ImageGallery,
  CaseStudyGrid,
} from "@dangerfarms/website-core";
import css from "@styled-system/css";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { transparentize } from "polished";
import React from "react";

const sectionOffset = 50;

const ProjectDetails = styled(Box)`
  & p + h1 {
    margin-top: 5px;
  }

  & h1 + p {
    margin-top: 2px;
  }
`;

const HeroImage = styled(Img)`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute !important; // Sorry! Easiest way to override gatsby-image.
  right: 0;
  top: 0;

  ${css({
    borderRadius: [0, 1],
    boxShadow: "large",
  })}
`;

const HeroImageAspectRatioBox = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;

const OffsetBackgroundBox = styled.div`
  bottom: ${() => `${2 * sectionOffset}px`};
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${css({
    backgroundColor: "paleGrey",
  })}
`;

const OffsetBox = styled.div`
  transform: ${() => `translateY(-${sectionOffset}px)`};
  margin-bottom: ${() => `-${sectionOffset}px`};
`;

const OffsetSection = styled.div`
  margin-top: ${() => `${sectionOffset}px`};
  position: relative;
  ${css({
    paddingX: [0, "gutter"],
  })}
`;

const HeroCaption = styled.div`
  background-color: ${({ theme }) => transparentize(0.1, theme.colors.fg)};
  max-width: 40rem;
  transform: translate(0, -50%);
  ${css({
    borderRadius: 1,
    boxShadow: "large",
    marginX: [2, 3, 0],
    padding: [3, 4],
    textAlign: ["center", "center", "left"],
  })}
  ${({ theme }) => theme.mediaQueries.wide} {
    transform: ${({ theme }) => `translate(-${theme.space[4]}, -50%)`};
  }
`;

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const CaseStudyTemplate = ({ data }) => {
  const caseStudy = data.prismic.allCase_studys.edges[0].node;
  const companyName = caseStudy.company_name
    ? RichText.asText(caseStudy.company_name)
    : null;
  const projectName = RichText.asText(caseStudy.project_name);
  const projectType = caseStudy.project_type;
  const heroImageSharp = caseStudy.hero_imageSharp.childImageSharp.fluid;
  const heroCaption = RichText.asText(caseStudy.hero_caption);
  const task = caseStudy.task;
  const solution = caseStudy.solution;
  const results = caseStudy.results;
  const demoVideo = caseStudy.demo_video;
  const gallery = caseStudy.gallery.filter(g => g.imageSharp !== null);
  const testimonials = caseStudy.testimonials
    .map(t => t.testimonial)
    .filter(testimonial => testimonial !== null);

  const relatedCaseStudies = caseStudy.case_study_showcase
    ? caseStudy.case_study_showcase
        .map(item => item.case_study)
        .filter(c => c !== null)
    : [];

  const mostRecentCaseStudies = data.prismic.mostRecentCaseStudies.edges
    .map(item => item.node)
    .filter(item => item._meta.uid !== caseStudy._meta.uid);

  if (!relatedCaseStudies[1]) {
    relatedCaseStudies[1] = mostRecentCaseStudies[1];
  }

  if (!relatedCaseStudies[0]) {
    relatedCaseStudies[0] = mostRecentCaseStudies[0];
  }

  return (
    <Page
      description={caseStudy.description}
      title={heroCaption || "Case Study"}
    >
      <Box as="article" paddingBottom={7} paddingTop={7}>
        <Stack align="stretch" space={5}>
          <Box backgroundColor="bg" paddingX="gutter">
            <ContentBlock>
              <Stack space={1}>
                <ProjectDetails>
                  {companyName && (
                    <Text variant="smallcaps">{companyName}</Text>
                  )}
                  <Text as="h1" variant="epic">
                    {projectName}
                  </Text>
                  <Text variant="lede">{projectType}</Text>
                </ProjectDetails>

                <Button as={Link} to="/case-studies" variant="back">
                  Back to case studies
                </Button>
              </Stack>
            </ContentBlock>
          </Box>

          <div>
            <OffsetSection>
              <OffsetBackgroundBox />
              <ContentBlock wide>
                <OffsetBox>
                  <HeroImageAspectRatioBox>
                    <HeroImage fluid={heroImageSharp} />
                  </HeroImageAspectRatioBox>
                </OffsetBox>
              </ContentBlock>
            </OffsetSection>

            <Box marginBottom={`-${sectionOffset}px`}>
              <ContentBlock
                alignItems={["center", "center", "flex-start"]}
                boxDisplay="flex"
                flexDirection="column"
              >
                <HeroCaption>
                  <Text color="bg">{heroCaption}</Text>
                </HeroCaption>
              </ContentBlock>
            </Box>
          </div>

          {task && (
            <Box backgroundColor="bg" paddingX="gutter">
              <ContentBlock>
                <TextContent>
                  <Stack space={[0.5, 2]}>
                    <Text as="h2" variant="heading">
                      The task
                    </Text>
                    <PrismicText richText={task} />
                  </Stack>
                </TextContent>
              </ContentBlock>
            </Box>
          )}

          {solution && (
            <Box backgroundColor="bg" paddingX="gutter">
              <ContentBlock>
                <TextContent>
                  <Stack space={[0.5, 2]}>
                    <Text as="h2" variant="heading">
                      The solution
                    </Text>
                    <PrismicText richText={solution} />
                  </Stack>
                </TextContent>
              </ContentBlock>
            </Box>
          )}

          {/* Spurious parent div preserves stack spacing */}
          {demoVideo && (
            <div>
              <OffsetSection>
                <OffsetBackgroundBox />
                <ContentBlock wide>
                  <OffsetBox>
                    <ResponsivePlayer embedUrl={demoVideo.embed_url} />
                  </OffsetBox>
                </ContentBlock>
              </OffsetSection>
            </div>
          )}

          {results && (
            <Box paddingX="gutter">
              <ContentBlock>
                <TextContent>
                  <Stack space={[0.5, 2]}>
                    <Text as="h2" variant="heading">
                      The result
                    </Text>
                    <PrismicText richText={results} />
                  </Stack>
                </TextContent>
              </ContentBlock>
            </Box>
          )}

          {gallery.length > 0 && (
            <Box paddingX="gutter">
              <ContentBlock wide>
                <ImageGallery images={gallery} />
              </ContentBlock>
            </Box>
          )}

          {testimonials.length > 0 && (
            <Box paddingX="gutter">
              <Stack space={1}>
                <ContentBlock>
                  <Testimonial
                    quote={RichText.asText(testimonials[0].quote)}
                    sourceJobTitle={
                      testimonials[0].source_job_title
                        ? RichText.asText(testimonials[0].source_job_title)
                        : null
                    }
                    sourceName={
                      testimonials[0].source_name
                        ? RichText.asText(testimonials[0].source_name)
                        : null
                    }
                  />
                </ContentBlock>
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
      {relatedCaseStudies.length > 0 && (
        <Section backgroundColor="paleGrey">
          <Stack align="stretch" space={3}>
            <Box alignItems="center" boxDisplay={["block", "flex"]}>
              <Text as="h2" variant="heading">
                More work
              </Text>
              <Box flex={1} />
              <Box boxDisplay={["none", "block"]}>
                <Button as={Link} to="/case-studies">
                  View all case studies
                </Button>
              </Box>
            </Box>
            <CaseStudyGrid caseStudies={relatedCaseStudies} />
          </Stack>
        </Section>
      )}
    </Page>
  );
};

export const query = graphql`
  query caseStudy($caseStudyUid: String) {
    __typename
    prismic {
      mostRecentCaseStudies: allCase_studys(
        sortBy: meta_lastPublicationDate_DESC
        first: 3
      ) {
        edges {
          node {
            ...CaseStudy
          }
        }
      }
      allCase_studys(uid: $caseStudyUid) {
        edges {
          node {
            description
            company_name
            _meta {
              uid
            }
            project_name
            project_type
            hero_image
            hero_caption
            demo_video
            task
            solution
            results
            company_name
            hero_imageSharp {
              childImageSharp {
                fluid(maxHeight: 765, maxWidth: 1360, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            gallery {
              imageSharp {
                id
                childImageSharp {
                  fluid(maxWidth: 1350, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              image
            }
            testimonials {
              testimonial {
                _linkType
                ... on PRISMIC_Testimonial {
                  quote
                  source_name
                  source_job_title
                }
              }
            }
            case_study_showcase {
              case_study {
                ... on PRISMIC_Case_study {
                  ...CaseStudy
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyTemplate;
